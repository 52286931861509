$desktop-width-breakpoint: 600px

html, body
  height: 100%
  margin: 0
  color: black
body.noscroll
  @media all and (max-width: $desktop-width-breakpoint)
    overflow: hidden
    position: fixed
    width: 100%
.wrapper
  min-height: 100vh
  width: 100%
  position: relative
  background-color: #fff
  display: flex
  flex-direction: column
  &.moved
    position: fixed
  .banners-top
    &.mobile
      @media all and (min-width: $desktop-width-breakpoint)
        display: none
    &.desktop
      @media all and (max-width: $desktop-width-breakpoint - 1px)
        display: none
  .banner-top-container
    width: 100%
    background: #F6F1ED
    display: flex
    align-items: center
    min-height: 32px
    border-bottom: 1px solid black
    transition: background 0.5s
    &.hide
      display: none
    @media all and (min-width: $desktop-width-breakpoint)
      justify-content: center
    a
      font-family: StyreneB-Light
      font-weight: 300
      font-size: 14px
      line-height: 17px
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      text-align: center
      width: 100%
      @media all and (min-width: $desktop-width-breakpoint)
        width: initial
      &:nth-of-type(2)
        margin-left: 4px
        &:before
          content: '|'
          margin-right: 4px

  main
    flex: 1
    &.header-margin
      padding-top: 40px
      @media all and (min-width: 700px)
        padding-top: 50px
    @media all and (max-width: 400px)
      .shop-container
        padding: 0
