$desktop-width-breakpoint: 700px

header.header
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  padding: 0
  height: 40px
  z-index: 1001
  background: transparent
  width: 100%
  position: absolute
  border-bottom: 1px solid black
  box-sizing: content-box
  transition: background 0.5s
  .content
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 20px
    width: 100%
    height: 100%
    @media all and (min-width: 1000px)
      margin: 0 70px
    .block
      display: flex
      justify-content: center
      align-items: center
      @media all and (min-width: 1400px)
        flex: 1
      &:nth-of-type(1)
        justify-content: flex-start
      &:last-of-type
        justify-content: flex-end
      @media all and (min-width: $desktop-width-breakpoint)
        &:nth-of-type(2)
          justify-content: flex-start
  &.sticky
    position: fixed
    top: 0
    background: #F6F1ED
    border-bottom: 1px solid #F6F1ED
  @media all and (min-width: $desktop-width-breakpoint)
    height: 50px

  .mobile-bar-icon
    cursor: pointer

  .logo
    text-align: center
    display: block
    width: 100%
    @media all and (min-width: $desktop-width-breakpoint)
      text-align: left
    a
      img
        height: 17px
        @media all and (min-width: $desktop-width-breakpoint)
          height: 19px

.rewards
  margin-left: 32px

.mobile
  @media all and (min-width: $desktop-width-breakpoint)
    display: none !important
.desktop
  @media all and (max-width: $desktop-width-breakpoint - 1px)
    display: none !important
