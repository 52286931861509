.bucket
  top: 2px
  position: relative
  right: 0
  width: 21px
  height: 18px
  cursor: pointer
  img
    vertical-align: baseline
    width: 100%
  .item-count
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    font-size: 10px
    z-index: 2
    font-family: StyreneB-Light
    font-weight: 300
    width: 100%
    height: 100%
    top: 0
    text-align: center
    margin-top: 2px
