@import '../../../ui_kit/variables'
@import '../footer/constants'
.copyright
  padding-top: 10px
  padding-bottom: 85px
  min-height: 60px
  margin: 0 20px
  font-size: 10px
  font-family: $font-normal
  @media all and (min-width: $desktop-width-breakpoint)
    padding-bottom: 0
    text-align: center
    text-transform: uppercase
  a
    text-decoration: underline !important
