html, body
  height: 100%
  margin: 0
  color: black
  font-size: 14px
body
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif
  @media all and (max-width: 600px)
    &.noscroll
      overflow: hidden
      position: fixed
      width: 100%
a, a:hover, a:link, a:visited
  text-decoration: none
  color: inherit
img
  vertical-align: middle
*, :before, :after
  box-sizing: border-box
.label:empty
  display: none
[role="button"], button
  cursor: pointer
.hidden
  display: none !important
