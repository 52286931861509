.login
  font-family: StyreneB-Light
  font-weight: 300
  font-size: 14px
  line-height: 14px
  text-transform: uppercase
  color: black
  max-width: 88px
  margin-right: 16px
  @media all and (min-width: 800px)
    margin-right: 30px
    max-width: unset
  .link
    font-family: StyreneB-Light
    font-weight: 300
    color: black
    &.white-background
      color: black
      &.sticky
        color: black
      &:hover
        color: #9DACE4
    &:hover
      color: white
      &.sticky
        color: #9DACE4
