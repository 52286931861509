@import './constants'
.footer-wrapper
  margin-top: 35px
  @media all and (min-width: $desktop-width-breakpoint)
    height: 170px
    margin-top: 60px
  .footer-container
    display: flex
    flex-direction: column
    padding: 15px 20px
    border-bottom: 1px solid
    border-top: 1px solid
    @media all and (min-width: $desktop-width-breakpoint)
      flex-direction: row
      justify-content: space-between
      height: 80px
      margin: 0 45px
      padding: 0
    .links-container
      display: flex
      align-items: flex-start
      flex-direction: column
      width: 60%
      justify-content: space-between
      line-height: 26px
      .link
        margin: 1px 0
        @media all and (min-width: $desktop-width-breakpoint)
          margin: 0
      @media all and (min-width: $desktop-width-breakpoint)
        width: 720px
        flex-direction: row
        align-items: center
        line-height: 12px
        .link
          a
            font-size: 9px
      @media all and (min-width: $large-desktop-width-breakpoint)
        .link
          a
            font-size: 12px
      .link:hover
        text-decoration: underline
