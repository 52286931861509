@import '../footer/constants'
.icons
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 130px
  margin-bottom: 10px
  margin-top: 3px
  align-items: center
  .icon
    img
      height: 20px
      width: 20px
  @media all and (min-width: $desktop-width-breakpoint)
    margin: 0
    width: 110px
    .icon
      img
        height: 18px
        width: 18px
