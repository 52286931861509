header
  &.blank
    height: 40px
    display: flex
    justify-content: center
    align-items: center
    @media all and (min-width: 1124px)
      border-bottom: 2px solid #DFDFDF
      height: 50px
    .logo
      display: block !important
      margin: 0
      a
        img
          width: 132px
          height: auto
          @media all and (min-width: 1124px)
            width: 158px
