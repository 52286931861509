noscript, .cookies-disabled-notice
  p
    font-size: 20px
    color: white
    background: red
    font-family: StyreneB-Bold
    margin: 0
    padding: 10px
    text-align: center
.cookies-disabled-notice
  display: none
