.category
  color: black
  &.white-background
    color: black
    a
      color: inherit
    &.active
      color: #9DACE4
      a
        color: inherit
    &.sticky
      color: black
      a
        color: inherit
  &.active
    color: white
    a
      color: inherit
    &.sticky
      color: #9DACE4
      a
        color: inherit
  a
    font-family: StyreneB-Bold
    font-size: 14px
    line-height: 14px
    text-align: center
