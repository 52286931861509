.dropdown
  width: 100%
  position: absolute
  left: 0
  top: 51px
  overflow: hidden
  .wrapper
    margin: 0 70px
    display: flex
    margin-bottom: 14px
