@import '../footer/constants'
.scroller
  margin: auto auto auto -5px
  display: flex
  img
    width: 35px
    -webkit-user-select: none
    -webkit-touch-callout: none
  @media all and (min-width: $desktop-width-breakpoint)
    margin: 0
    align-self: center
    img
      width: 71px
