.categories
  display: flex
  justify-content: space-between
  align-items: center
  width: 210px
  .low-priority
    display: none
    @media all and (min-width: 900px)
      display: block
  @media all and (min-width: 760px)
    width: 240px
  @media all and (min-width: 900px)
    width: 320px
  @media all and (min-width: 1200px)
    width: 470px
