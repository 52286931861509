@font-face {
  font-family: 'StyreneB-Bold';
  src: url('fonts/StyreneB/bold/StyreneB-Bold.woff') format('woff2'),
       url('fonts/StyreneB/bold/StyreneB-Bold.woff2') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'StyreneB-Light';
  src: url('fonts/StyreneB/light/StyreneB-Light.woff') format('woff2'),
       url('fonts/StyreneB/light/StyreneB-Light.woff2') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noe-Text';
  src: url("fonts/Noe-Text/Noe-Text.eot");
  src: url('fonts/Noe-Text/Noe-Text.woff') format('woff2'),
       url("fonts/Noe-Text/Noe-Text.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}
