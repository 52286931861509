$desktop-width-breakpoint: 700px

.account-dropdown
  width: 100%
  height: 100vh
  top: 0
  position: fixed
  z-index: 10000 !important
  display: flex
  @media all and (min-width: $desktop-width-breakpoint)
    margin-right: 85px
    margin-left: auto
    width: 467px
    height: 100%
    position: relative
    justify-content: space-between
    overflow: hidden
    text-transform: none
    box-shadow: 0 0 14px 0 rgba(130,145,201,0.20)
  .content
    width: 100%
    background: #FFF
    padding: 12px 20px 11px 22px
    position: relative
    @media all and (min-width: $desktop-width-breakpoint)
      width: 380px
    .close-button
      @media all and (min-width: $desktop-width-breakpoint)
        display: none
    .username
      color: #ece83a
      font-family: 'Noe-Text'
      font-size: 24px
      line-height: 20px
      padding-top: 16px
      padding-bottom: 5px
    .category
      color: #9EADE5
      font-family: 'StyreneB-Bold'
      font-size: 16px
      line-height: 20px
      text-transform: capitalize
      padding-top: 5px
    .title
      font-family: 'StyreneB-Bold'
      font-size: 14px
      line-height: 20px
      text-transform: uppercase
      padding-top: 3px
    .statistic
      display: flex
      flex-direction: column
      @media all and (min-width: $desktop-width-breakpoint)
        width: 306px
      .row
        display: flex
        justify-content: space-between
    .text
      font-family: 'StyreneB-Light'
      font-size: 14px
      line-height: 20px
      text-transform: capitalize
      border-bottom: 1px solid #E5E5E5
      padding: 3px 0 8px
      margin-bottom: 5px
      img
        padding-right: 5px
  .background
    display: flex
    width: 87px
    position: relative
    background-color: #9CADE4
    .image
      width: 100%
      opacity: 0.3
      background-image: url('../../../../../images/patterns/v-pattern.svg')
      background-position: left -58px top -60px
